export default defineI18nLocale(async () => {
  return {
    base: {
      privacy: 'Ao utilizar as soluções da Winx você concorda com a nossa {privacyPolicy}.',
      privacyPolicy: 'Política de privacidade',
    },
    titleBase: 'Pesquisa Winx',
    _pages: {
      _login: {
        _step1: {
          title: 'Pesquisa',
          subtitle: 'Para começar, selecione a forma de acesso informada pela sua empresa.',
          _optionEmail: {
            title: 'Acesso por e-mail',
            description: 'Utilze o seu e-mail de trabalho',
          },
          _optionPhone: {
            title: 'Acesso por SMS',
            description: 'Utilize o seu telefone de trabalho',
          },
          _optionDoc: {
            title: 'Acesso por CPF',
            description: 'Utilize o seu documento',
          },
        },
        _step2: {
          title: 'Acesso por @:{option}',
          subtitleOtp: 'Digite seu @:{option} de trabalho para receber um código de acesso único.',
          subtitle: 'Digite seu documento para acessar.',
          placeholderEmail: '@:wb.email de trabalho',
          placeholderPhone: '@:wb.phone de trabalho',
          placeholderDoc: '@:wb.document',
        },
        _step3: {
          title: 'Código de verificação',
          subtitle: 'Enviamos um código de 6 dígitos para o seu @:{option} de trabalho.',
          resendCode: 'Não recebeu o código?',
          resendByEmail: 'Enviar novo código por e-mail',
          resendByPhone: 'Enviar novo código por SMS',
        },
        changeOfAccess: 'Não é a forma de acesso correta?',
        support:
          'Se você não encontrar uma mensagem da Winnie com o código, dê uma olhada na sua caixa de SPAM. Ela pode estar lá.',
        comments: 'Se precisar de ajuda basta entrar em contato com a gente pela nossa {helpCenter}.',
        footer: 'Winx - Ampliar consciência para felicidade autêntica.',
        title: 'Acesso unificado',
        resendingAccess: 'Um novo código de acesso foi enviado para o seu @:{channel}.',
      },
      _index: {
        _header: {
          title: 'Oi, {name}. Bom te ver por aqui!',
          description:
            'Este espaço {private} e {encrypted} permite que você selecione quais pesquisas deseja responder e visualize seu histórico de participação nas ações d{companyArticle} {companyName} de forma segura.',
          private: 'privado',
          encrypted: 'criptografado',
        },
        _table: {
          _tabs: {
            research: 'Pesquisas',
            archive: 'Histórico',
          },
          _status: {
            start: 'Não iniciada',
            continue: 'Em andamento',
            home: 'Finalizada',
            answered: 'Respondida',
          },
          _action: {
            start: 'Iniciar',
            continue: 'Continuar',
          },
          _empty: {
            title: 'Nenhuma pesquisa disponível',
            message: 'Enquanto não temos nenhuma pesquisa para {action}, que tal um café?',
            answer: 'você responder',
            show: 'mostrar no seu histórico',
          },
          answered: 'Respondida',
          finishesAt: 'Encerra dia',
          answeredAt: 'Respondida dia',
          finishedAt: 'Finalizada dia',
        },
        title: 'Página inicial',
      },
      _research: {
        _start: {
          _message: {
            title: 'Mensagem d{companyArticle} {companyName}',
            _msg1: {
              title: 'Tempo de preenchimento',
              message:
                'Reserve um tempo de qualidade para responder à pesquisa. O formulário possui {count} e leva em média {tta} para ser preenchido.',
              answer: 'pergunta | perguntas',
              minute: 'minuto | minutos',
            },
            _msg2: {
              title: 'Segurança e privacidade',
              anonymous:
                'Fique tranquilo(a)! Todos os dados que você fornecer neste formulário são <strong>confidenciais e seguros</strong>.\n' +
                'Suas respostas são <strong>anônimas</strong>, e os resultados só aparecem em grupos <strong>maiores que quatro pessoas</strong>.\n' +
                'Isso significa que a {companyArticle} {companyName} não vai ver suas respostas individualmente nem saber que foram suas. Tudo bem <strong>protegido</strong>!',
              identified:
                'Fique tranquilo(a)! Todos os dados que você fornecer neste formulário são <strong>seguros</strong>.\n' +
                'Suas respostas são armazenadas nos nossos servidores e os resultados só estarão acessíveis para pessoas autorizadas d{companyArticle} {companyName}. Tudo bem <strong>protegido</strong>!',
              message:
                'Todos os dados coletados neste formulário são confidenciais e seguros e suas respostas são anônimas. {companyArticle} {companyName} {never} terá acesso às suas respostas de forma individual.',
              never: 'nunca',
            },
            _msg3: {
              title: 'Processamento de dados',
              message:
                'Ao começar a pesquisa, você concorda que a Winx armazene e processe as suas respostas de forma anonimizada.',
            },
          },
          _action: {
            deny: 'Agora não',
            accept: 'Iniciar a pesquisa',
          },
          welcome:
            'Temos uma breve mensagem d{companyArticle} {companyName} sobre o objetivo dessa ação e informações da Winx sobre a sua privacidade e segurança ao responder o formulário.',
          title: 'Iniciar pesquisa',
        },
        _finish: {
          _message: {
            title: 'Contribua com a Winx',
            message:
              'Nós da Winx somos obcecados pelos nossos usuários e clientes. Por isso, gostaríamos de saber sobre sua experiência com nossa plataforma.',
            _msg1: {
              title: 'Espalhe a notícia',
              message:
                'Convide seus colegas de trabalho a participarem das pesquisas. Quanto mais pessoas aderirem às ações d{companyArticle} {companyName}, mais efetivas serão as melhorias no ambiente de trabalho.',
            },
            _msg2: {
              title: 'Volte sempre',
              message:
                'Este ambiente é seu para contribuir continuamente nas pesquisas. Fique de olho nas próximas comunicações e continue respondendo nossos formulários e participando das ações promovidas.',
            },
          },
          _action: {
            home: 'Ir para tela inicial',
            rating: 'Avaliar experiência',
            back: 'voltar',
            send: 'Enviar avaliação',
          },
          _form: {
            title: 'Conta pra gente',
            answer: 'Deixe a sua opinião sincera sobre como foi utilizar a nossa plataforma.',
            label: 'Deixe seus comentários (opcional)',
            dislike: 'Não curti',
            like: 'Amei a Winx',
            done: 'Avaliação Concluída!',
            message:
              'Muito obrigada por avaliar a Winx. Sua contribuição é muito importante para a melhoria das nossas soluções.',
          },
          _footer: {
            title: 'Você ainda tem pesquisas para responder',
          },
          welcome: 'Suas respostas resultarão em ações concretas de melhorias do seu ambiente de trabalho.',
          title: 'Fico muito feliz com a sua contribuição',
        },
        _interaction: {
          _message: {
            title: 'Receba as minhas recomendações personalizadas',
            p1: 'Obrigado por responder ao formulário! Com base nas suas respostas, tenho algumas recomendações que irão auxiliar no seu autodesenvolvimento profissional.',
            p2: 'Se você quiser receber minhas mensagens, por favor, insira seu melhor endereço de e-mail no campo abaixo. Prometo que seu e-mail será utilizado somente para este propósito e não será compartilhado com ninguém. Eu, Winnie, não envio SPAM ou propagandas.',
            label: 'Seu melhor endereço de E-mail',
            _msg1: {
              title: 'Espalhe a notícia',
              message:
                'Convide seus colegas de trabalho a participarem das pesquisas. Quanto mais pessoas aderirem às ações d{companyArticle} {companyName}, mais efetivas serão as melhorias no ambiente de trabalho.',
            },
            _msg2: {
              title: 'Volte sempre',
              message:
                'Este ambiente é seu para contribuir continuamente nas pesquisas. Fique de olho nas próximas comunicações e continue respondendo nossos formulários e participando das ações promovidas.',
            },
          },
          _action: {
            back: 'Agora não',
            send: 'Quero receber',
          },
          welcome: 'Suas respostas resultarão em ações concretas de melhorias do seu ambiente de trabalho.',
          title: 'Fico muito feliz com a sua contribuição',
        },
        _denied: {
          _message: {
            _msg1: {
              title: 'Dê voz à sua opinião',
              message:
                'Essa pesquisa é uma excelente oportunidade para você compartilhar de forma anônima suas experiências, opiniões e sugestões sobre o seu trabalho.',
            },
            _msg2: {
              title: 'Prazo para preenchimento',
              message:
                'Encontre um momento mais tranquilo na sua rotina de trabalho. O prazo para responder é até o dia {finishesAt}.',
            },
            _msg3: {
              title: 'Segurança e privacidade',
              message:
                'Todos os dados coletados neste formulário são confidenciais e seguros e suas respostas são anônimas. {companyArticle} {companyName} {never} terá acesso às suas respostas de forma individual.',
              never: 'nunca',
            },
          },
          _action: {
            home: 'Voltar para o início',
          },
          welcome:
            'Entendo que este pode não ser o momento ideal para você participar. Sinta-se à vontade para voltar e responder à pesquisa mais tarde.',
          title: 'Pesquisa não respondida',
        },
        _form: {
          _instruction: {
            likert:
              'Considerando a opção {disagree} como o pior cenário e {agree}, o melhor, escolha na lista de expressões abaixo a opção que melhor representa a sua opinião em relação a afirmação realizada.',
            nps: 'Considerando {zero} como o pior cenário e {ten}, o melhor, escolha na lista abaixo a opção que melhor representa a sua opinião em relação à pergunta realizada.',
            radio:
              'Com base sua experiência no trabalho, escolha a opção abaixo que melhor representa a sua resposta para a afirmação ou pergunta realizada.',
            checkbox:
              'Considerando sua experiência no trabalho, escolha as opções abaixo que melhor representam a sua resposta para a afirmação ou pergunta realizada.',
            textarea:
              'Essa é uma pergunta de resposta dissertativa (escrita). Utilize o campo abaixo para escrever abertamente sobre o questionamento feito. Esse ambiente é anônimo e você não será identificado(a).',
            select:
              'Considerando sua experiência no trabalho, comece a digitar no campo de busca abaixo o nome da pessoa que melhor representa a resposta para a pergunta realizada. Adicione uma indicação por vez.',
            demographic:
              'Selecione a opção que melhor se aplica a você. Essa é uma resposta confidencial e anônima e sua participação é fundamental para garantir a representatividade dos dados.',
          },
          _likert: {
            agree: 'Concordo totalmente',
            partialAgree: 'Concordo parcialmente',
            neutral: 'Nem concordo nem discordo',
            partialDisagree: 'Discordo parcialmente',
            disagree: 'Discordo totalmente',
            na: 'Não se aplica',
          },
          _nps: {
            zero: '0 (zero)',
            ten: '10 (dez)',
          },
          _comment: {
            label: 'Caso queira, deixe um comentário que nos ajude a entender sua escolha:',
            placeholder: 'Faça seu comentário (opcional)',
          },
          _select: {
            label: 'Utilize o campo acima para pesquisar',
            disable: 'Limite de indicações atingido',
          },
          _textarea: {
            label: 'Digite sua resposta aqui',
          },
          _header: {
            title: 'Pergunta {number} de {total}',
          },
          _footer: {
            _action: {
              prev: 'Pergunta anterior',
              next: 'Próxima pergunta',
              skip: 'Prefiro não responder',
              answer: 'Responder',
              back: 'Voltar',
              finish: 'Finalizar',
            },
            confidential: 'Suas respostas são confidenciais e anônimas.',
          },
          _modal: {
            title: 'Chegamos ao final',
            message:
              'Essa é a última pergunta desse formulário. Ao clicar em {finish}, concluiremos sua participação nessa pesquisa e suas respostas serão armazenadas de forma de anonimizada pela Winx.',
          },
          title: 'Responder pesquisa',
        },
      },
    },
  };
});
